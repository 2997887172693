import React from "react";
import {Layout, Alert} from "antd";
import {Link} from "react-router-dom";
import PrettyBytes from "react-pretty-bytes";
import {toggleCollapsedSideNav} from "../../appRedux/actions";
import {ga} from '../../util/config'
import UserInfo from "../Sidebar/UserProfile";
import Auxiliary from "util/Auxiliary";


import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";

const {Header} = Layout;

const Topbar = () => {

  const { navStyle} = useSelector(({settings}) => settings);
  const { navCollapsed, width} = useSelector(({common}) => common);
  const { authUser} = useSelector(({auth}) => auth);

  const dispatch = useDispatch();

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src="/assets/images/w-logo.png" height="30px" width="30px"/></Link>
        <div className="gx-nav-disk">Disk: <PrettyBytes bytes={authUser.used_disk_space}/>/ <PrettyBytes bytes={authUser.limit_disk_space}/> {authUser.used_disk_space+ga > authUser.limit_disk_space ? (<span style={{color: 'red'}}>Sắp hết dung lượng</span>) : ""}
         </div>
      <ul className="gx-header-notifications gx-ml-auto">

          <Auxiliary>
            <li className="gx-user-nav"><UserInfo user={authUser}/></li>
          </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
