module.exports = {

  footerText: 'Copyright EPIS Cloud Singapore 2021',
  url_api: "https://api-storage.myepis.cloud/api",
  url_api1: "http://localhost:8000/api",

  location_servers: {
    's3' :'Singapore',
    's3vietnam' : 'Việt Nam',
  },
  limit_disk_space_default: 536870912,//512*1024*1024

  ga: 20971520//20*1024*1024

}
