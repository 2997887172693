import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const getAllFiles = (user_id, data, cb) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios(`manage/${user_id}/files`, { params: data}).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "MANAGE_ALLFILES", payload: data.data.data});
        cb(data.data)
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const renameFiles = (user_id, id, e) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.put(`manage/${user_id}/files/${id}`, {rename: e}).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "MANAGE_RENAMEFILE", payload: {id: id, newname: e}});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const downloadFile = () => {
};

export const deleteFiles = (user_id, id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.delete(`manage/${user_id}/files/${id}`).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "MANAGE_DELFILE", payload: id});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const newFiles = (user_id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`manage/${user_id}/files`).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "MANAGE_ADDFILE", payload: data.data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const newFileUpload = (user_id, data) => {
  return (dispatch) => {
    if(data.result){
      dispatch({type: FETCH_START});
      dispatch({type: FETCH_SUCCESS});
      if(data.data && data.data.size) dispatch({type: "USER_UPDATE_SIZE", payload: data.data.size});
      dispatch({type: "MANAGE_ADDFILE", payload: data.data});
    }
  }
};
