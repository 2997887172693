import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import { message } from "antd";

export const addUsers = (data, cb) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    axios.post('users', data).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "USER_CREATE", payload: data.data});
        cb(data.data)
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUsers = (data, cb) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    axios.get('users', {params: data}).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        if(data.data.data) {
          cb(data.data.data);
          dispatch({type: "USER_LISTS", payload: data.data.data});
        }
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
export const deleteUsers = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    axios.delete(`users/${id}`).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "USER_DELETE", payload: id});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateUsers = (id, data, cb) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    axios.put(`users/${id}`, data).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "USER_UPDATE", payload: data.data});
        cb(data.data)
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const changePassword = (data, cb) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.patch(`users`, data).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "USER_UPDATE_PASSWORD", payload: data.data});
        cb(data.data)
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {

      dispatch({type: FETCH_ERROR, payload: error.message});

      console.log("Error****:", error.message);
    });
  }
};
