import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}manager/:id`} component={asyncComponent(() => import('./manager/index'))}/>
      <Route path={`${match.url}manager`} component={asyncComponent(() => import('./manager/index'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./profile'))}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./user_manager'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./dashboard'))}/>
    </Switch>
  </div>
);

export default App;
