
const INIT_STATE = {
  files: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'MANAGE_ADDFILE' : {
      return {...state, files: addFile(state.files, action.payload)}
    }
    case "MANAGE_ALLFILES": {
      return {...state, files: action.payload};
    }
    case 'MANAGE_DELFILE': {
      return {...state, files: state.files.filter(({id}) => id !== action.payload)}
    }
    case 'MANAGE_RENAMEFILE': {
      return {...state, files: renameFile(state.files, action.payload)}
    }
    default:
      return state;
  }
}
function renameFile(arrs, payload){
  //đổi tên
  return arrs.map(arr => arr.id === payload.id ? {...arr, name: payload.newname} : arr);
}
function addFile(arr, payload){
  //push thêm
  let newArr = [payload, ...arr];
  return newArr;
}

