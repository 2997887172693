import axios from 'axios';
import {url_api} from './config';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: url_api,//process.env.REACT_APP_API_PATH,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {

    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });
  instance.interceptors.response.use((response) => {
    if(response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  }, (error) => {
    if(error.response.status === 401){
      localStorage.removeItem("token");
      window.location.href = '/signin';
    }
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
      return Promise.reject(error.message);
  });
  return instance;
};

export default fetchClient();
