import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

  let {themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);
  let {authUser} = useSelector(({auth}) => auth);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-chart"/>
                <span>Statistics</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="manager">
              <Link to="/manager"><i className="icon icon-folder"/>
                <span>Manager</span>
              </Link>
            </Menu.Item>
            {authUser.id === 1
            && <Menu.Item key="users">
            <Link to="/users"><i className="icon icon-user"/>
              <span>Users</span>
            </Link>
          </Menu.Item>}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

