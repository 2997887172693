import {INIT_URL, SIGNOUT_USER_SUCCESS, UPDATE_LOAD_USER, USER_DATA, USER_TOKEN_SET} from "../../constants/ActionTypes";

const INIT_STATE = {
  users: [],
  user: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'USER_LISTS' : {
      return {
        ...state,
        users: action.payload
      }
    }
    case 'USER' : {
      return {
        ...state,
        user: action.payload
      }
    }
    case 'USER_UPDATE' : {
      return {...state, users: update(state.users, action.payload)}
    }
    case 'USER_DELETE' : {
      return {...state, users: state.users.filter(({id}) => id !== action.payload)}
    }
    case 'USER_CREATE' : {
      return {...state, users: add(state.users, action.payload)}
    }
    default:
      return state;
  }
}
function update(arrs, payload){
  //đổi tên
  return arrs.map(arr => arr.id === payload.id ? {...arr, ...payload} : arr);
}
function add(arr, payload){
  //push thêm
  let newArr = [payload, ...arr];
  return newArr;
}
